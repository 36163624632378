.report-list {
  list-style: none;
  padding: 0;
}

.report-list li {
  padding: 10px 0 0 0;
}

.report-list li:not(:last-child) {
  padding: 10px 0 10px 0;
  border-bottom: 1px solid #F4F4F4;
}

.report-list li small {
  display: block;
  color: #999999;
}

.report-list li .ant-tag {
  display: table-cell;
}
