.amountList {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}

.amountList li {
  line-height: 1;
}

.amountList li:not(:last-child) {
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.amountList .currency {
  font-size: 24px;
  width: 58px;
  text-align: right;
  display: inline-block;
  margin-right: 10px;
}

.dueList {
  background-color: #ffffff;
  padding: 16px;
  margin-top: 6px;
  margin-bottom: 28px;
}

.dueList h5 {
  font-weight: 200;
  font-size: 24px;
  color: rgba(0, 0, 0, .65);
  margin: 0;
}
