.card-icon {
  display: flex;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
  margin-bottom: 4px;
  border-radius: 4px;
  min-height: 98px;
}

.card-icon .icon {
  align-self: center;
  margin-right: 8px;
  font-size: 42px;
  color: rgba(0, 0, 0, 0.4);
}

.card-icon small {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 12px;
  text-transform: uppercase;
}

.card-icon .content {
  font-size: 32px;
  line-height: 1;
  font-family: Roboto;
  font-weight: 200;
  margin-bottom: 2px;
}

.card-icon .description {
  color: rgba(0, 0, 0, 0.5);
}
