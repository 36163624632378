@charset "utf-8";
@import '~antd/dist/antd.css';

/* ## ANT DESIGN - MODIFICATIONS */

.ant-layout {
  background-color: #f0f2f5;
  background-image: linear-gradient(315deg, #f0f2f5 0%, #ededed 74%);
}

/* ## INPUTS BORDER COLOR */
/* .ant-input,
.ant-input-number,
.ant-tree-checkbox-inner,
.ant-btn:not(.ant-btn-primary),
.ant-select-selection,
.ant-checkbox-inner {
  border-color: #c4c4c4;
} */

.ant-input,
.ant-tree,
.ant-breadcrumb,
.ant-collapse,
.ant-form-item,
.ant-table,
.ant-descriptions-item-content {
  color: rgba(0, 0, 0, 0.8);
}


/* ## Breadcrumb */
.ant-breadcrumb {
  padding: 8px 20px;
  background-color: rgba(255, 255, 255, 0.6);
}

.ant-breadcrumb .anticon {
  margin-right: 8px;
  font-size: 18px;
}

.ant-breadcrumb,
.ant-breadcrumb-link a,
.ant-breadcrumb>span:last-child,
.ant-breadcrumb-separator {
  color: #52c41a;
  font-weight: 300;
  font-size: 18px;
}

.ant-breadcrumb-link a:hover {
  text-decoration: underline;
  color: #52c41a;
}

.ant-breadcrumb.inner {
  padding: 0;
  background-color: transparent;
}

.ant-breadcrumb.inner .ant-breadcrumb-link {
  margin-bottom: 10px;
  display: inline-block;
}


/* ## Table */
.ant-table {
  background-color: #ffffff;
}

.ant-table table tbody tr.ant-table-row-selected>td {
  background-color: #fffded;
}

.ant-table .ant-empty-normal {
  margin: 0;
}


/* ## Form */
.ant-form-vertical .ant-form-item-label {
  padding: 0 0 1px;
}

.ant-form-item:not(.ant-form-item-with-help) {
  margin-bottom: 12px;
}

/* Button */
/* .ant-btn:not(.ant-btn-icon-only) .anticon {
  margin-right: 4px;
} */


/* ## Popconfirm */
.ant-popover-arrow {
  display: none;
}


/* ## Tabs */
.ant-tabs.ant-tabs-card>.ant-tabs-bar .ant-tabs-tab {
  padding-bottom: 5px;
}


/* ## Badger */
.ant-badge {
  color: inherit;
}


/* ## Upload */
.ant-upload-list-item {
  margin-top: 2px;
}

.ant-upload-list-item:hover .ant-upload-list-item-info {
  background-color: #f7f7f7;
}

.ant-menu-horizontal>.ant-menu-item>a,
.ant-menu-item>a,
.ant-menu-item {
  color: rgba(0, 0, 0, 0.8);
}

.ant-layout-sider-dark,
.ant-layout-sider-dark .ant-menu-sub,
.ant-layout-sider-dark .ant-menu-item,
.ant-layout-sider-dark .ant-menu-item a,
.ant-layout-sider-dark .ant-menu-submenu,
.ant-layout-sider-dark .ant-menu-item-group-title {
  color: rgba(255, 255, 255, 0.8);
}

.ant-layout-sider-dark .ant-menu-item.ant-menu-item-active a,
.ant-layout-sider-dark .ant-menu-submenu.ant-menu-submenu-open {
  color: rgba(255, 255, 255, 1);
}

.ant-layout-sider-zero-width-trigger-left {
  font-size: 16px;
  top: 30px;
  background-color: rgba(0, 0, 0, 0.7);
  right: -26px;
  line-height: 30px;
  height: 30px;
  width: 26px;
}


/* ## Divider */
/* .ant-divider-horizontal.ant-divider-with-text.grey {
  margin: 12px 0;
  color: #bfbfbf;
} */
