@charset "utf-8";

.module-list button {
  width: 110px;
  height: 110px;
  margin-bottom: 2px;
  border-radius: 5px;
  color: rgba(0,0,0,0.25);
}

.module-list i.anticon-fork {
  transform: rotate(180deg);
}

.module-list .ant-col div {
  color: rgba(0,0,0,0.65);
}
