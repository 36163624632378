@charset "utf-8";

.help-list {
  list-style: none;
  color: rgba(0, 0, 0, 0.5);
}

.help-list li {
  line-height: 1.4em;
  margin-bottom: 10px;
}

.help-list li h4 {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.85);
  display: block;
}
