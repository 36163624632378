@charset "utf-8";

.management-title {
  color: #a5a5a5;
  display: block;
}

.detail-list {
  list-style: none;
  padding: 0;
  margin: 0;
  /* border: 1px solid #e8e8e8; */
  background-color: white;
  /*border-radius: 4px;*/
}

.detail-list li {
  margin: 2px 0 2px 0;
  line-height: 1.2;
  padding: 8px;
}

.detail-list li:not(:last-child) {
  border-bottom: 1px dotted #e8e8e8;
}

.detail-list li small {
  display: block;
  color: rgba(0, 0, 0, 0.5);
}
