@charset "utf-8";

ul.file-list {
  list-style: none;
  margin: 0;
  padding: 0;
}

ul.file-list li {
  background-color: #fff;
  margin-top: 4px;
  margin-bottom: 4px;
  padding: 8px;
}

ul.file-list li:hover {
  background-color: #e6f7ff;
}

ul.file-list li .file-icon {
  margin-right: 6px;
  float: left;
}

ul.file-list li small {
  display: block;
  color: #a5a5a5;
  margin-left: 26px;
}

ul.file-list li button {
  float: right;
}
