.totals-composition {
  background-color: white;
  padding: 16px;
  margin-bottom: 28px;
}

.totals-composition .ant-divider {
  margin: 14px 0;
}

.totals-composition .amount {
  font-size: 24px;
  line-height: 1;
  font-family: Roboto;
  font-weight: 200;
}

.totals-composition .type {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.totals-composition .item:not(:last-child) {
  margin-bottom: 40px;
}
