@charset "utf-8";

.task-list {
  list-style: none;
  padding: 0 10px 0 10px;
  margin: 0;
  background-color: #fff;
}

.task-list li {
  padding-top: 16px;
  padding-bottom: 16px;
  border-bottom: 1px dotted #e8e8e8;
}

.task-detail h4 {
  margin-right: 6px;
  color: #999999;
}
