@charset "utf-8";

@import url('https://fonts.googleapis.com/css?family=Roboto:100,100i,200,200i,300,300i');
/* @import url('https://fonts.googleapis.com/css?family=Roboto+Mono:400'); */

body {
  /* font-family: Roboto, sans-serif, Helvetica, Arial; */
  color: rgba(0, 0, 0, 0.8);
}

h1 {
  font-size: 32px;
  font-weight: 200;
  line-height: 1em;
  margin-bottom: 0;
}

h5 {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 300;
  margin-bottom: 4px;
  color: #b2b2b2;
}

h6 {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 200;
  border-bottom: 1px solid #e3e6ea;
  margin-bottom: 16px;
  line-height: 1em;
}

.uppercase {
  text-transform: uppercase;
}

.float-right {
  float: right;
}

.soft-box {
  background-color: rgba(255, 255, 255, .4);
  padding: 10px;
  max-width: 940px;
}

.white-box {
  background-color: #ffffff;
  padding: 10px;
  max-width: 940px;
}

.form-footer-buttons button {
  margin-right: 4px;
}

.icon-help {
  cursor: pointer;
  color: #999;
  font-size: 20px;
}

.margin-top-formitem {
  margin-top: 19px;
}

.border {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
}

.generic-label {
  margin-right: 6px;
  color: #999999;
}

/* ## Complete Layout */
#complete-layout .trigger {
  font-size: 18px;
  padding: 0 31px;
  cursor: pointer;
  transition: color 0.3s;
  color: rgba(255, 255, 255, 0.8);
}

#complete-layout .trigger:hover {
  color: #FFFFFF;
}

/* ## Header icons */
#header-options {
  display: flex;
  align-items: center;
  color: rgba(255, 255, 255, 0.8) !important;
  padding-right: 20px;
}

#header-options a {
  color: rgba(255, 255, 255, 0.8);
}

/* ## Helpers */
.no-margin {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.more-options {
  padding: 1px 3px;
  line-height: 0;
  cursor: pointer;
  font-size: 16px;
}

.more-options:hover {
  color: #1890ff;
}

/* ## Notification list */
.notification-list li:not(:first-child) {
  padding-top: 12px;
}

.notification-list li:not(:last-child) {
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 12px;
}

.notification-list .not-read .description {
  font-weight: bold;
}

.notification-list small {
  color: #999;
}

/* ## Card list with avatar on left */
.card-with-avatar {
  list-style: none;
  margin: 0;
  padding: 0;
}

.card-with-avatar li:not(:last-child) {
  margin-bottom: 28px;
}

.card-with-avatar-content {
  background-color: #fff;
  border-radius: 4px;
  padding: 10px;
  margin-left: 46px;
  min-height: 42px;
}

.card-with-avatar-content:before {
  content: '';
  float: left;
  width: 0;
  height: 0;
  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid #fff;
  margin-left: -18px;
  margin-top: 8px;
}

/* ## STAT */
.stat {
  display: flex;
  min-height: 98px;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.06);
  margin-bottom: 4px;
  border-radius: 4px;
}

.stat .big-icon {
  align-self: center;
  font-size: 42px;
  color: rgba(0, 0, 0, 0.3);
  margin-right: 16px;
}

.stat small.title {
  display: block;
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 16px;
}

.stat .content {
  font-size: 32px;
  line-height: 1;
  font-family: Roboto;
  font-weight: 200;
  margin-bottom: 2px;
}

.stat .description {
  color: rgba(0, 0, 0, 0.5);
}

/** Collapse-Title */
.collapse-title.ant-collapse>.ant-collapse-item>.ant-collapse-header,
.collapse-title.ant-collapse {
  line-height: 1.15;
  border: 0;
  background-color: transparent;
}

.collapse-title.ant-collapse .ant-collapse-item {
  border-bottom: 0;
}

.collapse-title.ant-collapse .ant-collapse-header {
  font-size: 20px;
  padding-top: 0;
  padding-bottom: 0;
}

.collapse-title.ant-collapse .ant-collapse-header:hover {
  color: #1890ff;
}

.collapse-title.ant-collapse .ant-collapse-content {
  border-top: none;
}

.collapse-title.ant-collapse>.ant-collapse-item.ant-collapse-no-arrow>.ant-collapse-header {
  padding-left: 0;
}

.collapse-title .ant-collapse-content label {
  display: block;
  margin-bottom: 3px;
  color: #a5a5a5;
  font-size: 85%;
  line-height: 1.35;
}

/** Table with actions buttons on left */
.list-with-buttons .buttons {
  float: left;
  width: 38px;
}

.list-with-buttons .buttons button {
  margin-bottom: 2px;
}

.list-with-buttons .list-container {
  margin-left: 40px;
}

.list-with-buttons .list-container .ant-table-wrapper,
.list-with-buttons .list-container .list-on-right {
  display: inline-block;
  width: 100%;
}

/** Topbar notifications menu */
.topbar-notification .ant-popover-inner-content {
  width: 360px;
  height: 480px;
  overflow: auto;
}
