@charset "utf-8";

.project-list {
  margin-top: 5px;
}

.project-list small {
  display: block;
  color: #666;
}
